import React from 'react';
import patrickimage from '../assets/patrick.jpeg';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { Link } from "react-scroll";

const Home = () => {
    const technologies = [
        "Javascript", "Tailwind CSS", "Bootstrap CSS", "HTML", "CSS",
        "ReactJs", "Flask", "GitHub", "SQLite", "MYSQL", "Django",
        "Python", "C#", "C Language", "ASP .Net", "Git", "Linux Commands"
    ];

    return (
        <div name="home" className="h-screen w-full bg-gradient-to-b from-black via-black to-gray-800">
            <div className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
                <div className="flex flex-col justify-center h-full">
                    <h2 className="text-4xl sm:text-7xl font-bold text-gray-400 pt-20">
                        I'm a Software Engineer
                    </h2>
                    <p className="text-gray-500 py-4 max-w-md">
                        With 2+ years of experience in building and designing software.
                        I've also served as a Technical Assistant and Project Coordinator in the telecommunications sector.
                        Currently, I specialize in web application development using technologies like:
                        <div className="flex items-center justify-center px-4 flex-wrap">
                            {technologies.map((tech, index) => (
                                <span key={index} className="text-gray-300 bg-gray-999 border border-gray-600 font-bold uppercase text-[.7rem] m-0.5 px-2 py-1 rounded">
                                    {tech}
                                </span>
                            ))}
                        </div>
                    </p>

                    <div>
                        <Link
                            to="portfolio"
                            smooth
                            duration={500}
                            className="group text-white w-fit px-6 py-3 my-3 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer"
                        >
                            Portfolio
                            <span className="group-hover:rotate-90 duration-300">
                                <MdOutlineKeyboardArrowRight size={25} className="ml-1" />
                            </span>
                        </Link>
                    </div>
                </div>
                <div className='w-3/4'>
                    <img src={patrickimage} alt="My Profile" className='rounded-2xl mx-auto md:w-full' />
                </div>
            </div>
        </div>
    );
};

export default Home;
