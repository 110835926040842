import React from 'react';
import { FaGithub, FaLinkedin, FaFileAlt } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import { BsFillPersonLinesFill, BsPersonWorkspace } from 'react-icons/bs';


const SocialLinks = () => {
    const resumeLink = "/resume.pdf";
    const transcriptslink = "academicresults.pdf";
    const freelanceposterlink = "/Freelancing_Poster.pdf";

    const links = [
        {
            id: 1,
            child: (
                <>
                    LinkedIn <FaLinkedin size={30} />
                </>
            ),
            href: "https://www.linkedin.com/in/tshupane-morake-028523167/",
            style: "rouned-tr-md"
        },
        {
            id: 2,
            child: (
                <>
                    Github <FaGithub size={30} />
                </>
            ),
            href: "https://github.com/Papase14",
        },
        {
            id: 3,
            child: (
                <>
                    Mail <HiOutlineMail size={30} />
                </>
            ),
            href: "mailto:tshupane@outlook.com",
        },
        {
            id: 4,
            child: (
                <>
                    Resume <BsFillPersonLinesFill size={30} />
                </>
            ),
            href: resumeLink,
            style: "rouned-br-md"
        },
        {
            id: 5,
            child: (
                <>
                    Freelance Poster <BsPersonWorkspace size={30} />
                </>
            ),
            href: freelanceposterlink,
            style: "rouned-br-md"
        },
        {
            id: 4,
            child: (
                <>
                    Transcripts <FaFileAlt size={30} />
                </>
            ),
            href: transcriptslink,
            style: "rouned-br-md"
        },
    ];
    return (
        <div className='hidden lg:flex flex-col top-[30%] left-0 fixed'>
            <ul>
                {links.map(({ id, child, href, style }) => (
                    <li key={id} className={"flex justify-between items-center w-40 h-14 px-4 ml-[-100px] bg-gray-500 hover:rounded-md duration-300 hover:ml-[-10px] " + style}>
                       <a href={href} className='flex justify-between items-center w-full text-white' target='_blank' rel="noreferrer">
                                {child}
                        </a>
                        
                        {/* Return Soon
                        (id === 4 || id === 5) ? (
                            <a href={href} className='flex justify-between items-center w-full text-white' target='_blank' rel="noreferrer">
                                {child}
                            </a>
                        ) : (
                            <a href={href} className='flex justify-between items-center w-full text-white' target='_blank' rel="noreferrer" download>
                                {child}
                            </a>
                        )*/}
                    </li>
                ))}
            </ul>
            {links[3] && (
                <div className="w-full h-full fixed top-0 left-0 bg-black bg-opacity-90 z-50 hidden" id="pdf-viewer">
                    <embed src={resumeLink} type="application/pdf" width="100%" height="100%" />
                    <embed src={freelanceposterlink} type="application/pdf" width="100%" height="100%" />
                </div>
            )}
        </div>
    );
};

export default SocialLinks;