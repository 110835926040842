import React from 'react';
import varsitystudentportal from '../assets/portfolio/varsitystudentportal.jpg';
import aupatelecom from '../assets/portfolio/aupatelecom.png';
import deardiary from '../assets/portfolio/deardiary.png';
import programworld from '../assets/portfolio/programworld.png';
import planeddportfolio from '../assets/portfolio/planeddportfolio.png'
import asanteprofile from '../assets/portfolio/asanteportfolio.png'


const Project = () => {
    const portfolios = [
        {
            id: 1,
            src: varsitystudentportal,
            description: "A web application designed to serve as a student portal for a university. It provides features such as course enrollment, grades, and announcements, and also admin and lecture views",
            technologies: ["SQLite", "Flask", "Python", "HTML", "Tailwind CSS"],
            clickDeploy: "https://papase14.github.io/Varsity-Student-Portal/",
            ClickCode: "https://github.com/Papase14/Varsity-Student-Portal"
        },
        {
            id: 2,
            src: aupatelecom,
            description: "A responsive and visually appealing telecom website that showcases various products and services offered by a telecommunications company",
            technologies: ["HTML", " Bootstrap CSS"],
            clickDeploy: "https://aupatelecom.co.za",
            ClickCode: "https://github.com/Papase14/Aupatelecom"
        },
        {
            id: 3,
            src: deardiary,
            description: "An online diary application allowing users to write and share their thoughts, memories, and reflections. It provides an open-ended platform for friends",
            technologies: ["SQLite", "Flask", "Python", "HTML", "Bootstrap CSS"],
            clickDeploy: "https://dear-diary-3404d674ca68.herokuapp.com",
            ClickCode: "https://github.com/Papase14/Dear-Diary"
        },
        {
            id: 4,
            src: programworld,
            description: "Welcome to Program-World, the official website of my coding company. At Program-World, we specialize in providing top-notch freelancing services across various tech domains.",
            technologies: ["HTML", "Javascript", "Bootstrap CSS"],
            clickDeploy: "http://program-world.co.za",
            ClickCode: "https://github.com/Papase14/Program-World"
        },
        {
            id: 5,
            src: planeddportfolio,
            description: "Welcome to Plan Edd, This is the official website for my client's portfolio page. he will be advertising his work and passion.",
            technologies: ["HTML", "Javascript", "CSS"],
            clickDeploy: "https://plan-edd.vercel.app",
            ClickCode: "https://github.com/Papase14/Plan-Edd"
        },
        {
            id: 6,
            src: asanteprofile,
            description: "Welcome to Asante Heights, This is the official website for an accomodation building called Asante. They will showing their profile to the world.",
            technologies: ["ReactJS", "CSS"],
            clickDeploy: "https://asante-iota.vercel.app/",
            ClickCode: "https://github.com/Papase14/Asante"
        }

    ];

    //  deactivate projects
    const showAlertProjects = [3];

    const redirectToUrl = (url, projectId) => {
        if (showAlertProjects.includes(projectId)) {
            // Display an alert for specific projects
            alert("App is offline. Deployment coming soon!");
        } else {
            // Redirect to the specified URL for other projects
            window.location.href = url;
        }
    };

    return (
        <div
            name="portfolio"
            className="bg-gradient-to-b from-black to-gray-800 w-full text-white md:h-screen"
        >
            <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
                <div className="pb-5">
                    <p className="text-4xl font-bold inline border-b-4 border-gray-500">
                        Portfolio
                    </p>
                    <p className="py-6">Check out some of my work right here</p>
                </div>

                <div className="pb-4 grid sm:grid-cols-2 md:grid-cols-3 gap-8 px-12 sm:px-0 ">
                    {portfolios.map(({ id, src, clickDeploy, ClickCode, technologies, description }) => (
                        <div key={id} className="shadow-md shadow-gray-600 rounded-lg w-full">
                            <div className="w-full h-40 overflow-hidden rounded-md">
                                <img
                                    src={src}
                                    alt=""
                                    className="w-full h-full object-cover duration-200 hover:scale-105"
                                />
                            </div>
                            <div className="pl-2 pr-2 mt-1 text-gray-500">
                                <p> {description}</p>
                                <div className="flex items-center justify-center w-full my-2">
                                    <div className="w-1/4 h-1 bg-gray-400"></div>
                                </div>
                                <p className="flex items-center justify-center px-4 flex-wrap">
                                    {technologies.map((tech, index) => (
                                        <span key={index} className="text-gray-300 bg-gray-999 border border-gray-600 font-bold uppercase text-[.7rem] m-0.5 px-2 py-1 rounded">
                                            {tech}
                                        </span>
                                    ))}
                                </p>
                            </div>
                            <div className="flex items-center justify-center">
                                <button onClick={() => redirectToUrl(clickDeploy, id)} className="w-full px-6 py-3  duration-200 hover:scale-105">
                                    Demo
                                </button>
                                {/*
                                    Lets not share the code

                                    <p>|</p>
                                    <button onClick={() => redirectToUrl(ClickCode)} className="w-1/2 px-6 py-3 m-4 duration-200 hover:scale-105">
                                        Code
                                    </button>
                                */}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};


export default Project;