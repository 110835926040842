import React, { useState } from 'react';
import { FaBars, FaTimes, FaGithub, FaLinkedinIn, FaFileAlt } from 'react-icons/fa';
import { Link } from "react-scroll";
import { AiOutlineMail } from 'react-icons/ai';
import { BsFillPersonLinesFill, BsPersonWorkspace } from 'react-icons/bs';

const NavBar = () => {
  const [nav, setNav] = useState(false);
  const resumeLink = "/resume.pdf";
  const transcriptslink = "academicresults.pdf";
  const freelanceposterlink = "/Freelancing_Poster.pdf";

  const links = [
    {
      id: 1,
      link: "home"
    },
    {
      id: 2,
      link: "about"
    },
    {
      id: 3,
      link: "portfolio"
    },
    {
      id: 4,
      link: "experience"
    },
    {
      id: 5,
      link: "contact"
    },
  ];

  // This is to display  Resume
  //const openPdfViewerResume = () => {
  //  window.open(resumeLink, '_blank');
  //};

  // This is to request user to contact if which to view file
  const redirectToContactBeforeResume = (resumeUrl) => {
    // Condition for showing alert before redirecting to contact
    const shouldShowAlert = true;  // You can modify this condition based on your needs

    if (shouldShowAlert) {
        // Show an alert and then redirect to contact section
        alert("You need to contact me first before viewing the resume.");
        window.location.href = "#contact";  // Redirect to contact section
    } else {
        // Redirect to the resume directly
        window.location.href = resumeUrl;
    }
};
  const openPdfViewerTrancsript = () => {
    window.open(transcriptslink, '_blank');
  };
  const openPdfViewerFreelance = () => {
    window.open(freelanceposterlink, '_blank');
  };

  return (
    <div className="flex justify-between items-center w-full h-20 px-4 text-white bg-black fixed z-50">
      <div>
        <h1 className="text-3xl font-serif italic ml-2">Pat-World</h1>
      </div>

      <ul className="hidden md:flex">
        {links.map(({ id, link }) => (
          <li
            key={id}
            className="px-4 cursor-pointer capitalize font-medium text-gray-500 hover:scale-105 duration-200"
          >
            <Link to={link} smooth duration={500}>
              {link}
            </Link>
          </li>
        ))}
      </ul>

      <div
        onClick={() => setNav(!nav)}
        className="cursor-pointer pr-4 z-10 text-gray-500 md:hidden"
      >
        {nav ? <FaTimes size={30} /> : <FaBars size={30} />}
      </div>

      {nav && (
        <ul className="flex flex-col justify-center items-center absolute top-0 left-0 w-full h-screen bg-gradient-to-b from-black to-gray-800 text-gray-500 z-40">
          {links.map(({ id, link }) => (
            <li
              key={id}
              className="px-4 cursor-pointer capitalize py-6 text-3xl"
            >
              <Link
                onClick={() => setNav(!nav)}
                to={link}
                smooth
                duration={500}
              >
                {link}
              </Link>
            </li>
          ))}
          <li>
            <div className='flex items-center justify-between space-x-5 my-4 w-full sm:w-[80%]'>
              <a
                href="https://www.linkedin.com/in/tshupane-morake-028523167/"
                target='_blank'
                rel='noreferrer'
              >
                <div className='rounded-full shadow-lg shadow-gray-400 p-3 cursor-pointer hover:scale-105 ease-in duration-300'>
                  <FaLinkedinIn size={25} />
                </div>
              </a>
              <a
                href="https://github.com/Papase14"
                target='_blank'
                rel='noreferrer'
              >
                <div className='rounded-full shadow-lg shadow-gray-400 p-3 cursor-pointer hover:scale-105 ease-in duration-300'>
                  <FaGithub size={25} />
                </div>
              </a>
              <a
                href="mailto:tshupane@outlook.com"
                target='_blank'
                rel='noreferrer'
              >
                <div className='rounded-full shadow-lg shadow-gray-400 p-3 cursor-pointer hover:scale-105 ease-in duration-300'>
                  <AiOutlineMail size={25} />
                </div>
              </a>

            </div>
          </li>
          <li>
            <div className='flex items-center justify-between space-x-5 my-4 w-full sm:w-[80%]'>
              <div
                className='rounded-full shadow-lg shadow-gray-400 p-3 cursor-pointer hover:scale-105 ease-in duration-300'
                onClick={() => redirectToContactBeforeResume("/resume.pdf")}
              >
                <BsFillPersonLinesFill size={25} />
              </div>
              <div
                className='rounded-full shadow-lg shadow-gray-400 p-3 cursor-pointer hover:scale-105 ease-in duration-300'
                onClick={openPdfViewerTrancsript}
              >
                <FaFileAlt size={25} />
              </div>
              <div
                className='rounded-full shadow-lg shadow-gray-400 p-3 cursor-pointer hover:scale-105 ease-in duration-300'
                onClick={openPdfViewerFreelance}
              >
                <BsPersonWorkspace size={25} />
              </div>
            </div>
          </li>
        </ul>
      )}

    </div>
  );
};

export default NavBar;
